import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'components/navbar/Navbar';
import Slider from 'react-slick';
import Slide1 from 'assets/images/home-slider/dubai.jpg';
import Slide2 from 'assets/images/home-slider/jordan.jpg';
import Slide3 from 'assets/images/home-slider/peru.jpg';
import Slide4 from 'assets/images/home-slider/china.jpg';
import Slide5 from 'assets/images/home-slider/antarctica.jpg';
import Slide6 from 'assets/images/home-slider/egypt.jpg';
import Slide7 from 'assets/images/home-slider/india.jpg';
import Slide8 from 'assets/images/home-slider/sydney.jpg';

const heroImage = function () {
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide1})`}}/>
          <div className="slider-caption">Burj Al Arab, Dubai, United Arab Emirates</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide2})`}}/>
          <div className="slider-caption">Petra, Wadi Musa, Jordan</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide3})`}}/>
          <div className="slider-caption">Huayna Picchu, Aguas Calientes, Peru</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide4})`}}/>
          <div className="slider-caption">Terracotta Army, Shaanxi, China</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide5})`}}/>
          <div className="slider-caption">Iceberg, Antarctica</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide6})`}}/>
          <div className="slider-caption">The Great Pyramid, Giza, Egypt</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide7})`}}/>
          <div className="slider-caption">Taj Mahal, Agra, India</div>
        </div>
        <div>
          <div className="bg-cover bg-slider-cover" style={{backgroundImage: `url(${Slide8})`}}/>
          <div className="slider-caption">Opera House, Sydney, Australia</div>
        </div>
      </Slider>
    </div>
  );
};

const settings = {
  className: 'section-hero-slider opacity-max',
  dots: false,
  infinite: true,
  fade: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  pauseOnHover: false,
  swipe: false
};

const HeroWithNavbar = ({children, navbar}) =>
  <div className="section-hero">
    <div className="section-hero-inner">
      {heroImage()}
      <header className="section-hero-header">
        <Navbar navbar={navbar} />
      </header>
      <div className="container position-relative">
        { children }
      </div>
    </div>
  </div>;

HeroWithNavbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]),
  navbar: PropTypes.object.isRequired
};

export default HeroWithNavbar;
