import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Textarea = ({name, value, id, className, rows, cols, placeholder, hasError, onChange}) => {
  const htmlClasses = classNames(
    'form-control',
    className,
    {
      'has-error': hasError
    }
  );

  return (
    <textarea
      name={name}
      value={value}
      id={id}
      className={htmlClasses}
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Textarea.defaultProps = {
  id: null,
  className: null,
  placeholder: null,
  hasError: false,
  rows: '5',
  cols: '60'
};

export default memo(Textarea);
