import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-plus';
import classNames from 'classnames';

const SelectPlus = ({name, value, id, className, clearable, hasError, onChange, options, placeholder}) => {
  const htmlClasses = classNames(
    className,
    {
      'has-error': hasError
    }
  );

  return (
    <Select
      id={id}
      placeholder={placeholder}
      className={htmlClasses}
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      clearable={clearable}
    />
  );
};

SelectPlus.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

SelectPlus.defaultProps = {
  id: null,
  className: null,
  hasError: false,
  clearable: false
};

export default memo(SelectPlus);
