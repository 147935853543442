import React from 'react';
import Slider from 'react-slick';
import Section from 'helpers/Section';
import LazyImage from 'helpers/LazyImage';
import AwardImage1 from 'assets/images/awards/award1.svg';
import AwardImage2 from 'assets/images/awards/award2.svg';
import AwardImage3 from 'assets/images/awards/award3.svg';
import AwardImage4 from 'assets/images/awards/award4.svg';
import AwardImage5 from 'assets/images/awards/award5.svg';
import AwardImage6 from 'assets/images/awards/award6.svg';
import AwardImage7 from 'assets/images/awards/award7.svg';
import AwardImage8 from 'assets/images/awards/award8.svg';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  arrows: true,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};

const Awards = () =>
  <Section
    sectionClass="pt-6 bg-white"
    containerStyle={{marginTop: '-24px'}}
  >
    <Slider {...settings}>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage1} />
      </a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage2} />
      </a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage3} />
      </a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage4} />
      </a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage5}
        />
      </a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage6}
        /></a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage7} />
      </a>
      <a href="/awards" className="p-2">
        <LazyImage
          title="award"
          alt="award"
          imgClass="grayscale-max grayscale-hover"
          src={AwardImage8} />
      </a>
    </Slider>
  </Section>;

export default Awards;
