import * as R from 'ramda/src/index';
import {
  MOBILE_WIDTH, TABLET_WIDTH, DESKTOP_WIDTH, XLARGE_WIDTH,
  XXLARGE_WIDTH , brand, environment, assetHost, isAirtickets
} from 'helpers/stableValues';
import {getLanguages} from 'helpers/commonHelper';

const centerMapCoordinates = [37.9838, 23.7275]; // Athens

const CMS_DOMAIN = environment === 'production' ?
  `https://cms.${location.hostname.replace(/^www\./, '')}` : 
  `https://cms.${brand}.com`;

const PUBLISH = 'publish';

const replaceCmsUrl = (url) => {
  if (url?.includes(CMS_DOMAIN)) {
    const possibleUrl = url.replace(`${CMS_DOMAIN}`, '');
    const lang = possibleUrl.substring(1).split('/')[0];
    if ((lang.length === 2 && getLanguages.includes(lang)) || isAirtickets) {
      return `${location.origin}${possibleUrl}`;
    } else if (possibleUrl) {
      return `${location.origin}/en${possibleUrl}`;
    }
    return `${location.origin}/en/ferry`; // due to cms en language
  }
  return url;
};

const getPathname = (string) => {
  try {
    return new URL(string);
  } catch (err) {
    return {pathname: string};
  }
};

const replaceAssetsImgUrl = (string, width) => {
  if (typeof string === 'string') {
    const {pathname} = getPathname(string);
    if (width && !pathname.endsWith('.svg')) {
      const path = pathname.split('/');
      path.splice(2, 0, `w${width}`);
      return `${assetHost}${path.join('/')}`;
    }
    return `${assetHost}${pathname}`;
  }

  return string;
};

const replaceCmsContent = (text) => {
  if (typeof text === 'string' && text?.includes(CMS_DOMAIN)) {
    return text.replaceAll(CMS_DOMAIN, assetHost);
  }
  return text;
};

const calcBackgroundFullWidth = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth > XLARGE_WIDTH) {
    return XXLARGE_WIDTH;
  } else if (windowWidth > DESKTOP_WIDTH) {
    return DESKTOP_WIDTH;
  } else if (windowWidth > TABLET_WIDTH) {
    return TABLET_WIDTH;
  }

  return MOBILE_WIDTH;
};

const getBreadcrump = (breadcrump) =>
  breadcrump.map((item, index) => (
    {
      ...item,
      isLast: index + 1 === breadcrump.length
    }));

const tranformItem = (attrs) => R.pipe(
  R.filter(({post_status}) => (post_status === PUBLISH)),
  R.map(R.pick(attrs)),
  R.map((item) => ({
    ...item,
    permanlink: replaceCmsUrl(item.permanlink)
  }))
);

const getMenu = (menu, translations, menuTrs) =>
  Object.keys(menu).reduce((acc, item) => {
    if (menu[item]) {
      acc.push([item, translations[menuTrs[item]]]);
    }
    return acc;
  }, []);

const getHeadingTitle = (h1, title) => h1 || title.rendered;

export {
  centerMapCoordinates,
  PUBLISH,
  replaceCmsUrl,
  replaceAssetsImgUrl,
  replaceCmsContent,
  calcBackgroundFullWidth,
  getBreadcrump,
  tranformItem,
  getMenu,
  getHeadingTitle
};
