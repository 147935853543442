import React, {memo} from 'react';
import PropTypes from 'prop-types';

const Radio = ({name, value, radioValue, disabled, onChange}) => (
  <input
    type="radio"
    name={name}
    value={radioValue}
    checked={value === radioValue}
    disabled={disabled}
    onChange={onChange}
  />
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  radioValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(Radio);
