import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Section from 'helpers/Section';
import LazyImage from 'helpers/LazyImage';
import MediaPriceAt from 'images/usps/price-at.svg';
import MediaSafeAt from 'images/usps/safe-at.svg';
import MediaSpeedAt from 'images/usps/speed-at.svg';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [{
    breakpoint: 800,
    settings: {
      dots: true,
      slidesToShow: 2
    }
  }, {
    breakpoint: 480,
    settings: {
      dots: true,
      slidesToShow: 1
    }
  }]
};

const slides = function (translations) {
  const {
    firstTitle,
    secondTitle,
    thirdTitle,
    firstDescription,
    secondDescription,
    thirdDescription
  } = translations;
  const items = [
    {
      icon: MediaSpeedAt,
      title: firstTitle,
      description: firstDescription
    },
    {
      icon: MediaSafeAt,
      title: secondTitle,
      description: secondDescription
    },
    {
      icon: MediaPriceAt,
      title: thirdTitle,
      description: thirdDescription
    }
  ];

  return items.map(({icon, title, description}, i) =>
    <div key={i} className="text-center px-3">
      <LazyImage imgClass="d-inline-block mb-3" src={icon} alt={title} title={title} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const Features = ({translations}) =>
  <Section
    sectionClass="py-8 bg-white"
    renderTitle={() => (
      <h1 className="display-2 text-center font-light mb-6">{translations.headline}</h1>
    )}
  >
    <Slider {...settings}>
      {slides(translations)}
    </Slider>
  </Section>;

Features.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  translations: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    firstTitle: PropTypes.string.isRequired,
    thirdTitle: PropTypes.string.isRequired,
    firstDescription: PropTypes.string.isRequired,
    secondDescription: PropTypes.string.isRequired,
    thirdDescription: PropTypes.string.isRequired
  })
};

export default Features;
