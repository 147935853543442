import {min, max, map, getOr} from 'lodash/fp';
import * as R from 'ramda/src/index';
import general from 'config/general.json';
import {
  formatPrice, formatDateWithDay, formatDateShort, formatDateMonth, formatDateDay, formatMinTextDayUpperCase
} from 'lib/helpers/globalize-helper';
import sanitizeHtml from 'sanitize-html';
import {locales, website, isAirtickets, isAffiliate, bookingBaseUrl} from 'helpers/stableValues';
import {affiliateHost, isEsky} from 'ferries/helpers/common';
import {loadBeacon, openBeaconChat} from 'helpers/events';
import {getEskyDomain} from 'components/navbar/refMenu';
import tpLogo from 'assets/images/logo-tp.svg';
import tp24Logo from 'assets/images/logo-tp24.svg';
import fsLogo from 'assets/images/logo-fs-blue.svg';
import atLogo from 'assets/images/logo-at.svg';
import atLogoGrey from 'assets/images/footer/logo-at-grey.svg';
import tpLogoGrey from 'assets/images/footer/logo-tp-grey.svg';
import tp24LogoGrey from 'assets/images/footer/logo-tp24-grey.svg';
import fsLogoGrey from 'assets/images/footer/logo-fs-grey.svg';
import tp24LogoAway from 'assets/images/logo-tp24.svg';
import fsLogoAway from 'assets/images/logo-fs-blue.svg';
import Translation from 'lib/helpers/translation';

const FLIGHT_HOUR_DESCRIPTION = 5;

const airlineImageURL = (logoURL) => `${general.airlineImgBasePath}${logoURL}`;
const getCity = (text) => text.split('-')[0];
const getRestCity = (text) => text.slice(text.indexOf('-') + 1);
const segmentTechnicalStopDuration = (duration) => getDuration(duration);
const formatGetDayMonth = (date) => formatDateMonth(date);
const formatGetDay = (timestamp) => formatDateDay(dateToUTC(timestamp));
const formatDate = (timestamp) => formatDateShort(dateToUTC(timestamp));
const getDate = (date) => date.getDate();
const cleanHtml = (description) =>
  sanitizeHtml(description, {
    allowedTags: [ 'p', 'a', 'ul', 'ol', 'li', 'br', 'b', 'strong' ],
    allowedAttributes: {
      'ul': ['class'],
      'a': [ 'href', 'target' ]
    },
    transformTags: {
      'h1': 'p',
      'h2': 'p',
      'h3': 'p',
      'h4': 'p',
      'h5': 'p',
      'h6': 'p',
      'ul': sanitizeHtml.simpleTransform('ul', {class: 'check-list'})
    }
  });

function showELAL(segment) {
  const existInRoute = ((dep, arr, depArray, arrArray) => depArray.includes(dep) && arrArray.includes(arr));
  const depAirportCodes = ['TLV'];
  const arrAirportCodes = ['BER', 'BUD', 'KBP', 'LCA', 'PRG'];
  const {airlineCode, dep, arr, flightNumber, stopDestination} = segment;
  const existInRoute1 = existInRoute(dep, arr, depAirportCodes, arrAirportCodes);
  const existInRoute2 = existInRoute(dep, arr, arrAirportCodes, depAirportCodes);
  return (
    airlineCode === 'LY' &&
    flightNumber.length === 4 &&
    !stopDestination &&
    (existInRoute1 || existInRoute2)
  );
}

function getDuration(totalSeconds) {
  const hours = Math.floor(totalSeconds / (60 * 60));
  const divisor_for_minutes = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);
  const obj = {
    h: hours > 9 ? hours : '0' + hours,
    m: minutes > 9 ? minutes : '0' + minutes
  };
  return obj.h + Translation.tr('hours_short', 'time') + ' ' + obj.m +
    Translation.tr('minutes_short', 'time');
}

function dateToUTC(timestamp) {
  const date = _date(timestamp);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(),
    date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(),
    date.getUTCSeconds());
}

function _date(timestamp) {
  if (timestamp > 1893456000) {
    return new Date(timestamp);
  }
  return new Date(timestamp * 1000);
}

function getHoursAndMinutes(timestamp) {
  const date = _date(timestamp);
  return {
    hours: date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours(),
    minutes: date.getUTCMinutes() > 9 ? date.getUTCMinutes() : '0' + date.getUTCMinutes()
  };
}

function formatTime(timestamp) {
  const hoursAndMinutes = getHoursAndMinutes(timestamp);
  return hoursAndMinutes.hours + ':' + hoursAndMinutes.minutes;
}

function stopsText(segments, countTechnicalStops) {
  const stops = segments.length - 1 + countTechnicalStops;
  if (stops === 1) {
    return `${stops} ${Translation.tr('stop', 'stopsCount')}`;
  } else if (stops > 1) {
    return `${stops} ${Translation.tr('stops', 'stopsCount')}`;
  }
  return Translation.tr('nonStop', 'stopsCount');
}

function translateNoOfSeats(num) {
  if (num === 1) {
    return Translation.tr('hurryOneSeat', 'seats');
  }

  return Translation.tr('hurryLastSeats', 'seats').replace('{numberOfSeats}', num);
}

function infoFreeBaggage(segments, version) {
  const versionTypes = {
    short: {
      oneWithoutWeight: 'baggageIncluded',
      oneWithWeight: 'oneWithWeightShort',
      manyWithWeight: 'manyWithWeightShort',
      manyWithoutWeight: 'manyWithoutWeightShort'
    },
    middle: {
      oneWithoutWeight: 'oneWithoutWeightMiddle',
      oneWithWeight: 'oneWithWeightMiddle',
      manyWithWeight: 'manyWithWeightMiddle',
      manyWithoutWeight: 'manyWithoutWeightMiddle'
    },
    long: {
      oneWithoutWeight: 'oneWithoutWeightLong',
      oneWithWeight: 'oneWithWeightLong',
      manyWithWeight: 'manyWithWeightLong',
      manyWithoutWeight: 'manyWithoutWeightLong'
    }
  };
  let key = versionTypes[version].oneWithoutWeight;
  if (!segments[0].baggage) {
    return Translation.tr(key, 'baggageFreeInfo');
  }
  const maxQuantity = max(map('baggage.quantity', segments));
  const minMaxWeight = min(map('baggage.maxWeight', segments));
  if (maxQuantity === 1 && minMaxWeight !== 0) {
    key = versionTypes[version].oneWithWeight;
  } else if (maxQuantity > 1) {
    key = minMaxWeight !== 0 ? versionTypes[version].manyWithWeight : versionTypes[version].manyWithoutWeight;
  }
  const measureUnit = segments[0].baggage.measureUnit === 'K' ? 'kg' : segments[0].baggage.measureUnit;
  return Translation.tr(key, 'baggageFreeInfo')
    .replace('{numberBaggages}', maxQuantity)
    .replace('{numberWeight}', minMaxWeight + measureUnit);
}

function infoFreeBaggageMetaSearch(baggages, version) {
  const versionTypes = {
    short: {
      oneWithoutWeight: 'baggageIncluded',
      manyWithoutWeight: 'manyWithoutWeightShort'
    },
    middle: {
      oneWithoutWeight: 'oneWithoutWeightMiddle',
      manyWithoutWeight: 'manyWithoutWeightMiddle'
    },
    long: {
      oneWithoutWeight: 'oneWithoutWeightLong',
      manyWithoutWeight: 'manyWithoutWeightLong'
    }
  };
  let key = versionTypes[version].oneWithoutWeight;
  if (baggages) {
    return Translation.tr(key, 'baggageFreeInfo');
  }
  if (baggages > 1) {
    key = versionTypes[version].manyWithoutWeight;
  }
  return Translation.tr(key, 'baggageFreeInfo')
    .replace('{numberBaggages}', baggages);
}

function segmentStopDuration(index, segments) {
  const seconds = segments[index + 1].depTimestamp - segments[index].arrTimestamp;
  return getDuration(seconds);
}

function getTypeClass(bookingClass, cabinClass) {
  let type = '';
  if (bookingClass) {
    type = bookingClass;
  } else if (cabinClass) {
    type = cabinClass;
  }
  return type;
}

function formatDurationTime(totalSeconds) {
  const hours = Math.floor(totalSeconds / (60 * 60));

  const divisor_for_minutes = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const obj = {
    h: hours > 9 ? hours : '0' + hours,
    m: minutes > 9 ? minutes : '0' + minutes
  };
  return obj.h + Translation.tr('hours_short', 'time') + ' ' + obj.m +
    Translation.tr('minutes_short', 'time');
}

function differentAirportSegments(segments) {
  const differentAirports = R.dropLast(1, segments)
    .map((segment, index) => segment.arr !== segments[index + 1].dep);
  return Boolean(differentAirports.length);
}

const getTotalConnectionCombinationCount = (results) => {
  if (results.length) {
    return results.reduce((acc, result) => acc + R.flatten(result.highlightIDs).length, 0);
  }

  return 0;
};

function getTypeNextDayArrival(timestamp, dateTomorrow) {
  const date = dateToUTC(new Date(timestamp * 1000));
  if (date > dateTomorrow) {
    if (date.getHours() <= FLIGHT_HOUR_DESCRIPTION) {
      return 'midnightArrival';
    }
    return 'nextDayArrival';
  }
  return false;
}

function getCompanyPath(brand, market) {
  const path = {
    airtickets: {
      az: 'o-nas',
      pl: 'o-nas',
      ru: 'o-nas',
      tr: 'bizimle-ilgili'
    },
    travelplanet24: {
      gr: 'history'
    }
  };
  return getOr('about-us', [brand, market], path);
}

function getTypeNextDayArrivalMeta(departure, arrival) {
  if (departure.getDate() !== arrival.getDate()) {
    if (arrival.getHours() <= 5) {
      return 'midnightArrival';
    }
    return 'nextDayArrival';
  }
  return false;
}

function getTopLevelDomain() {
  const hostname = window.location.hostname;
  const subdomainLabel = hostname.split('.')[0];
  const subdomain = hostname.replace(`${subdomainLabel}.`, '');
  return subdomain;
}

const threeLetterCodePattern = /.*\(([a-zA-Z]{3})\).*/;
const datePattern = /(\d{4})-(\d{2})-(\d{2})/;
function getThreeLetterCode(string = '') {
  if (string.length === 3) {
    return string.toUpperCase();
  }

  const matchString = string.match(threeLetterCodePattern);
  return matchString && matchString[1].toUpperCase();
}

const isEmpty = R.either(R.isEmpty, R.isNil);

const arrayEquals =(a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val) => b.includes(val));

const currentLanguage = window.location.pathname.substring(1).split('/')[0];

const getLanguages = R.pipe(
  R.values,
  R.map(({locale}) => locale.split('-')[0])
)(locales);

const languageInUrl = currentLanguage.length === 2 && getLanguages.includes(currentLanguage);

const languageFromUrl = () => languageInUrl ? `/${currentLanguage}` : '';

const brandLogos = [
  {name: 'airtickets', logo: atLogo, footer: atLogoGrey, away: atLogo},
  {name: 'tripsta', logo: tpLogo, footer: tpLogoGrey, away: tpLogo},
  {name: 'travelplanet24', logo: tp24Logo, footer: tp24LogoGrey, away: tp24LogoAway},
  {name: 'ferryscanner', logo: fsLogo, footer: fsLogoGrey, away: fsLogoAway}
];

const brandLogo = (brand, logo) =>
  R.pipe(
    R.find(R.propEq('name', brand || 'airtickets')),
    R.prop(logo || 'logo')
  )(brandLogos);

const getHost = () => {
  if (isEsky) {
    return getEskyDomain();
  }
  if (isAffiliate) {
    return affiliateHost;
  }
  return isAirtickets ? website : `${languageFromUrl()}/${bookingBaseUrl}`;
};

const setSession = (items) => {
  Object.keys(items).map((key) => {
    sessionStorage.setItem(key, items[key]);
  });
};

const addDataLayer = ({event, bookingId}) => {
  window.dataLayer = window.dataLayer || [];
  const data = Object.assign({},
    event && {event},
    bookingId && {bookingId}
  );
  if (!R.isEmpty(data)) {
    window.dataLayer.push(data);
  }
};

const openBeacon = () => {
  if (beaconIsEnabled()) {
    addDataLayer({event: openBeaconChat});
  } else {
    addDataLayer({event: loadBeacon});
    addDataLayer({event: openBeaconChat});
  }
};

const beaconIsEnabled = () => Boolean(window.Trengo);

export {
  airlineImageURL,
  formatTime,
  getTypeNextDayArrival,
  getTypeNextDayArrivalMeta,
  showELAL,
  getCity,
  getRestCity,
  formatDurationTime,
  stopsText,
  translateNoOfSeats,
  infoFreeBaggage,
  infoFreeBaggageMetaSearch,
  segmentStopDuration,
  segmentTechnicalStopDuration,
  getTypeClass,
  formatGetDayMonth,
  formatGetDay,
  formatPrice,
  formatDate,
  formatDateShort,
  formatDateWithDay,
  differentAirportSegments,
  getTotalConnectionCombinationCount,
  cleanHtml,
  formatMinTextDayUpperCase,
  getDate,
  getCompanyPath,
  getTopLevelDomain,
  threeLetterCodePattern,
  datePattern,
  getThreeLetterCode,
  getLanguages,
  languageFromUrl,
  isEmpty,
  arrayEquals,
  brandLogo,
  getHost,
  setSession,
  addDataLayer,
  openBeacon
};
