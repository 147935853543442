import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FieldContainer = ({id, className, floatingLabel, value, hasError, showFloatingLabel, children}) => {
  const htmlClasses = classnames(
    className,
    {
      'has-error': hasError,
      'floating-label': floatingLabel,
      'floating-label-show': (floatingLabel && !!value?.length) || showFloatingLabel
    }
  );

  return (
    <div
      id={id}
      className={htmlClasses}
    >
      {children}
    </div>
  );
};

FieldContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  floatingLabel: PropTypes.bool.isRequired,
  showFloatingLabel: PropTypes.bool.isRequired,
  value: PropTypes.string,
  hasError: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

FieldContainer.defaultProps = {
  id: null,
  className: null,
  floatingLabel: true,
  value: false,
  hasError: false,
  showFloatingLabel: false,
  children: null
};

export default memo(FieldContainer);
