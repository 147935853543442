import createSearchMaskStore from 'components/SearchMask/SearchMaskStore';
import navbarStore from 'components/navbar/NavbarStore';
import footerStore from 'components/footer/FooterStore';
import getAppStore from 'components/getApp/GetAppStore';
import NewsletterStore from 'components/newsletter/NewsletterStore';

const searchMaskStore = createSearchMaskStore({showRecentQueries: true});
export function createHomeStore(
  uspTranslations, topOffersTranslations, brand, market, isAirtickets, numOfAdsHome
) {
  return {
    searchMaskStore,
    brand,
    market,
    isAirtickets,
    usp: {
      brand, translations: uspTranslations
    },
    getAppStore,
    topOffers: {
      translations: topOffersTranslations,
      numOfAds: numOfAdsHome
    },
    newsletter: NewsletterStore,
    footerStore,
    navbarStore
  };
}
