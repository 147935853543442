import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda/src/index';

const TextInput = (props) => {
  const {type, className, hasError} = props;

  const inputProps = R.pipe(
    R.clone,
    R.omit(['hasError'])
  )(props);

  const htmlClasses = classNames('form-control', className, {
    'has-error': hasError
  });

  return (
    <input
      {...inputProps}
      type={type || 'text'}
      className={htmlClasses}
    />
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

TextInput.defaultProps = {
  id: null,
  className: null,
  placeholder: null,
  hasError: false
};

export default memo(TextInput);
