import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda/src/index';

const renderOption = ({value, label}) => (
  <option key={value} value={value}>
    {label}
  </option>
);

renderOption.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

const renderOptions = (options, label) => {
  const children = options.map((item) => {
    if (item.options) {
      return (
        <optgroup key={item.id} label={item.label}>
          {item.options.map(renderOption)}
        </optgroup>
      );
    }

    return renderOption(item);
  });

  if (options.length && options[0].options) {
    children.unshift(renderOption({value: '', label}));
  }

  return children;
};

const Select = (props) => {
  const {id, name, value, label, hasError, onChange, options, className} = props;
  const selectProps = R.pipe(
    R.clone,
    R.omit(['hasError'])
  )(props);
  const htmlClasses = classNames(
    'form-control',
    className,
    {
      'has-error': hasError
    }
  );

  return (
    <select
      {...selectProps}
      id={id}
      className={htmlClasses}
      name={name}
      value={value}
      onChange={onChange}
    >
      {renderOptions(options, label)}
    </select>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string
};

Select.defaultProps = {
  id: null,
  className: null,
  hasError: false
};

export default memo(Select);
