import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda/src/index';
import Slider from 'react-slick';

const settings = {
  arrows: true,
  dots: true,
  speed: 500,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  }, {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }]
};

function slides(numOfAds) {
  return R.range(1, numOfAds + 1).map((i) =>
    <div key={i} id={`homepage-carousel-${i}`} className="p-sm"></div>
  );
}

const TopOffers = function ({translations, numOfAds}) {
  return (
    <section id="top-offers" className="py-8 text-center bg-white">
      <div className="container">
        <h1 className="display-2 font-light mb-4">{translations.dealsTitle}</h1>
        <p className="text-lead">{translations.dealsDescription}</p>
        <Slider {...settings}>
          {slides(numOfAds)}
        </Slider>
        <div id="homepage-test"></div>
      </div>
    </section>
  );
};

TopOffers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  translations: PropTypes.shape({
    dealsTitle: PropTypes.string.isRequired,
    dealsDescription: PropTypes.string.isRequired
  }),
  numOfAds: PropTypes.number.isRequired
};

export default TopOffers;
