import React from 'react';
import {render} from 'react-dom';
import * as R from 'ramda/src/index';
import qs from 'qs';
import Cookies from 'js-cookie';
import {createHomeStore} from './store';

import HeroWithNavbar from './components/HeroWithNavbar';
import SearchMask from 'components/SearchMask';
import Features from 'components/features/Features';
import TopOffers from 'components/offers/TopOffers';
import GetApp from 'components/getApp/GetApp';
import Newsletter from 'components/newsletter/Newsletter';
import Footer from 'components/footer';
import Awards from 'components/awards/Awards';
import {
  uspTranslations,
  topOffersTranslations,
  mainTranslations
} from 'helpers/translations';
import {brand, market, isAirtickets, numOfAdsHome, isProduction} from 'helpers/stableValues';

const paramsForCookie = R.pick(['Pixel_ID', 'msclkid'], qs.parse(location.search.slice(1)));
Object.keys(paramsForCookie).forEach((param) => {
  Cookies.set(param, paramsForCookie[param], {path: '/', expires: 30, secure: isProduction, sameSite: 'lax'});
});

const {
  navbarStore, searchMaskStore, usp, getAppStore, footerStore, newsletter, topOffers
} = createHomeStore(
  uspTranslations, topOffersTranslations,
  brand, market, isAirtickets, numOfAdsHome
);

function renderGetApp(appData) {
  const {appstoreUrl, playstoreUrl} = appData;
  return (appstoreUrl || playstoreUrl) && <GetApp store={appData} />;
}

render(
  <div>
    <HeroWithNavbar navbar={navbarStore}>
      <h1 className="display-1 text-light text-center w-md-1-2 mx-auto">
        {mainTranslations.headline}
      </h1>
      {
        !isAirtickets && <p className="d-xs-none text-large text-light text-center w-md-1-2 mx-auto opacity-mid">
          {mainTranslations.description}
        </p>
      }
      <SearchMask store={searchMaskStore} />
    </HeroWithNavbar>
    {
      isAirtickets && market === 'gr' && <Awards />
    }
    <Features {...usp} />
    {topOffers.numOfAds > 0 && <TopOffers {...topOffers} />}
    <Newsletter store={newsletter} />
    {getAppStore.isAirtickets && renderGetApp(getAppStore, market, brand)}
    <Footer store={footerStore} />
  </div>
  , document.getElementById('home')
);
