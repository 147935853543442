import {localeFull, currency, isoCurrency} from 'helpers/stableValues';
import moment from 'moment';
import memoize from 'lodash/memoize';
import * as R from 'ramda/src/index';

// Default formatting functions based on moment format for dates and custom price formatting function
// Those will only be used if window.Intl is not available
let _formatDateShort = (date) => moment(date).format('L');
let _formatDateFull = (date) => moment(date).format('dddd, LL');
let _formatDateWithDay = (date) => moment(date).format('ddd, L');
let _formatMinTextDay = (date) => moment(date).format('ddd');
let _format12Hour = (date) => moment(date).format('h:m a');
let _format24Hour = (date) => moment(date).format('HH:mm');
let _formatTimePeriod = (date) => moment(date).format('s');
let _formatMonthDayPeriod = (date) => moment(date).format('ddd, D MMM');
let _formatYearMonthDayPeriod = (date) => moment(date).format('ddd, D MMM YYYY');
// Using L instead of hardcoding d/M on the following two functions due to differences in locales.
// Note that we add the year in the result but at least we do not confuse the customers expecting M/d
let _formatDateMonth = (date) => moment(date).format('L');
let _formatDateMonthNil = (date) => date.format('L')
  .replace(new RegExp('[^.]?' + moment(date).format('YYYY') + '.?'), '');
let _formatDayDateMonth = (date) => moment(date).format('ddd, L');
let _formatDay24Hour = (date) => moment(date).format('ddd HH:mm');
let _formatDateDate = (date) => moment(date).format('D');
let _formatDateMonthYear = (date) => moment(date).format('MMM YYYY');
let _formatPrice = (amount = 0, currencyCode = isoCurrency()) => {
  const {precision, symbol} = currency();
  // check if the currencyCode is the same as the defaultCurrency and then
  // use the symbol info passed on _tp24 object, otherwise just print
  // the currencyCode passed
  currencyCode = currencyCode === isoCurrency()
    ? symbol
    : currencyCode;
  const parsedAmount = Number.parseFloat(amount);
  return Number.isNaN(parsedAmount)
    ? `${amount} ${currencyCode}`
    : `${parsedAmount.toFixed(precision)} ${currencyCode}`;
};

const isArabicLocale = R.flip(R.includes)(['ar-SA']);

const getCachedIntlCurrencyFormatter = memoize(function (locale, currencyCode) {
  let formatPrice;
  try {
    formatPrice = new Intl.NumberFormat(
      locale,
      {
        style: 'currency',
        currency: currencyCode
      }
    ).format;
  } catch (ignore) {
    formatPrice = new Intl.NumberFormat(locale).format;
  }
  return formatPrice;
}, (locale, currencyCode) => locale + '_' + currencyCode);

export const setLocale = (locale) => {
  if (!locale) {
    return localeFull;
  }
  locale = locale.replace('_', '-');
  moment.locale(locale);
  if (isArabicLocale(locale)) {
    moment.updateLocale(locale.toLowerCase(), {preparse: null, postformat: null});
  }
  if (typeof window.Intl !== 'object') {
    return;
  }
  if (isArabicLocale(locale)) {
    locale += '-u-nu-latn-ca-gregory';
  }
  _formatDateShort = new Intl.DateTimeFormat(locale, {day: 'numeric', month: 'numeric', year: 'numeric'}).format;
  _formatDateFull = new Intl.DateTimeFormat(
    locale,
    {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}
  ).format;
  _formatDateWithDay = new Intl.DateTimeFormat(
    locale,
    {weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric'}
  ).format;
  _formatMinTextDay = new Intl.DateTimeFormat(locale, {weekday: 'short'}).format;
  _format12Hour = new Intl.DateTimeFormat(locale, {hour: 'numeric', minute: 'numeric', hour12: true}).format;
  // _format24Hour = new Intl.DateTimeFormat(locale, {hour: 'numeric', minute: 'numeric', hour12: false}).format;
  _formatTimePeriod = new Intl.DateTimeFormat(locale, {second: 'numeric', hour12: false}).format;
  _formatMonthDayPeriod = new Intl.DateTimeFormat(locale, {weekday: 'short', day: 'numeric', month: 'short'}).format;
  _formatYearMonthDayPeriod = new Intl.DateTimeFormat(
    locale, {
      weekday: 'short', day: 'numeric', month: 'short', year: 'numeric'
    }
  ).format;
  _formatDateMonth = new Intl.DateTimeFormat(locale, {day: 'numeric', month: 'numeric'}).format;
  _formatDayDateMonth = new Intl.DateTimeFormat(locale, {day: 'numeric', month: 'numeric', weekday: 'short'}).format;
  // _formatDay24Hour = new Intl.DateTimeFormat(
  //   locale,
  //   {hour: 'numeric', minute: 'numeric', hour12: false, weekday: 'short'}
  // ).format;
  _formatDateDate = new Intl.DateTimeFormat(locale, {day: 'numeric'}).format;
  _formatDateMonthYear = new Intl.DateTimeFormat(locale, {month: 'short', year: 'numeric'}).format;
  _formatPrice = function (amount = 0, currencyCode = isoCurrency()) {
    return getCachedIntlCurrencyFormatter(locale, currencyCode)(amount);
  };
};

export const formatNumberDecimal = (number, decimal = 2) => +(number).toFixed(decimal);
export const formatPrice = (price, currencyCode) => _formatPrice(price, currencyCode);
export const formatDateMonthYear = (value) => _formatDateMonthYear(value);
export const formatDateDate = (value) => _formatDateDate(value);
export const formatDay24Hour = (value) => _formatDay24Hour(value);
export const formatDayDateMonth = (value) => _formatDayDateMonth(value);
export const formatDateShort = (value) => _formatDateShort(value);
export const formatDateFull = (value) => _formatDateFull(value);
export const formatMinTextDayUpperCase = (value) => _formatMinTextDay(value).toUpperCase();
export const format12Hour = (date) => _format12Hour(date);
export const format24Hour = (date) => _format24Hour(date);
export const formatTimePeriod = (date) => _formatTimePeriod(date);
export const formatMonthDayPeriod = (date) => _formatMonthDayPeriod(date);
export const formatYearMonthDayPeriod = (date) => _formatYearMonthDayPeriod(date);
export const formatDateMonth = (date) => _formatDateMonth(date);
export const formatDateMonthNil = (date) => _formatDateMonthNil(date);
export const formatDateDay = (date) => _formatMinTextDay(date);
export const formatDateWithDay = (value) => _formatDateWithDay(value);

setLocale(localeFull);
